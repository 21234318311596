import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDev: false,
    customerInfo: {
      customerId: '',
      customerName: '',
      customerHeadImg: '',
      empPk: '',
      scheduleId: '',
      scheduleDate: '',
      scheduleType: '',
      prjId: '',
      prjCode: '',
      prjName: '',
      channelId: '',
      channelCode: '',
      channelName: '',
      channelCityAdName: '',
      channelProvinceAdName: '',
      channelLongitude: '',
      channelLatitude: ''
    },
    showLoading: false,
    errorCode: '5000:无权限访问',
    qyOpenId: '', // 企业微信openid
    pqyOpenId: '', // 权限系统使用的openid,可能会带':'+appCode的形式,所以区分qyOpenId
    orgCode: '', // 组织code
    appCode: '', // 应用code
    token: '', // 调用授权接口需要token
    scheduleId: '' // 排班ID
  },
  mutations: {
    // 开启关闭开发模式
    setDev (state, isDev) {
      state.isDev = isDev
    },
    changeCustomerInfo (state, info) {
      state.customerInfo.customerId = info.customerId
      state.customerInfo.customerName = info.customerName
      state.customerInfo.customerHeadImg = info.customerHeadImg
      state.customerInfo.empPk = info.empPk
      state.customerInfo.scheduleId = info.scheduleId
      state.customerInfo.scheduleDate = info.scheduleDate
      state.customerInfo.scheduleType = info.scheduleType
      state.customerInfo.prjId = info.prjId
      state.customerInfo.prjCode = info.prjCode
      state.customerInfo.prjName = info.prjName
      state.customerInfo.channelId = info.channelId
      state.customerInfo.channelCode = info.channelCode
      state.customerInfo.channelName = info.channelName
      state.customerInfo.channelCityAdName = info.channelCityAdName
      state.customerInfo.channelProvinceAdName = info.channelProvinceAdName
      state.customerInfo.channelLongitude = info.channelLongitude
      state.customerInfo.channelLatitude = info.channelLatitude
    },
    // 是否显示loading
    updateLoading (state, showLoading) {
      state.showLoading = showLoading
    },
    // 更新页面状态码提示
    updateErrorCode (state, errorCode) {
      state.errorCode = errorCode
    },
    // 更新企业微信openid
    updateQyOpenId (state, qyOpenId) {
      state.qyOpenId = qyOpenId
    },
    // 权限系统使用的openid
    updatePQyOpenId (state, pqyOpenId) {
      state.pqyOpenId = pqyOpenId
    },
    // 更新第三方权限code
    updateOrgCode (state, orgCode) {
      state.orgCode = orgCode
    },
    // 更新应用code
    updateAppCode (state, appCode) {
      state.appCode = appCode
    },
    // 若存在，说明用户未绑定，需要注册后用此token更新权限系统里用户的绑定状态
    updateToken (state, token) {
      state.token = token
    },
    // 若存在，说明是指定排班打卡
    updateScheduleId (state, scheduleId) {
      state.scheduleId = scheduleId
    }
  },
  actions: {
  },
  modules: {
  }
})
