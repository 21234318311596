<template>
  <div class="Training">
    <!-- <van-nav-bar
      title="一页精要"
      left-text="返回"
      left-arrow
      fixed
      @click-left="onClickLeft"
    /> -->
    <div>
      <van-image :src="trainingUrl" />
    </div>
    <div>
      <van-button type="info" block round to="/Questions" class="returnBtn">返回</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Training',
  data () {
    return {
      trainingUrl: ''
    }
  },
  created () {
    var self = this
    self.login(self).then(res => {
      var prjId = self.$store.state.customerInfo.prjId
      if (prjId) {
        // 设置Loading
        self.$store.commit('updateLoading', true)
        self.$http({
          url: '/p1mk/svc/view/training/v1/getPictureTraining',
          method: 'get',
          async: false,
          params: { prjId }
        }).then(res => {
          console.log(res)
          self.trainingUrl = res.data.data.pictureTraining
          self.$store.commit('updateLoading', false)
        }).catch(err => {
          console.log(err)
          self.$store.commit('updateLoading', false)
        })
      }
    })
  },
  methods: {
    onClickLeft () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less">
.Training{
  margin-top : 46px;
  margin-bottom : 40px;
  .returnBtn{
    position:fixed;
    bottom:5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    width: 80%;
    margin-left: 10%;
  }
  .van-nav-bar{
    background-color:#1989fa
  }
  .van-nav-bar__title{
    color:#fff
  }
  .van-nav-bar .van-icon{
    color:#fff
  }
}
</style>
