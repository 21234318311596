<template>
<div>
  <van-empty image="error" :description="this.$store.state.errorCode" />
  <div v-if="this.$store.state.isDev">
    <router-link to="/Training">一页精要</router-link> |
    <router-link to="/Questions">考题</router-link> |
    <router-link to="/ClockIn">上班</router-link> |
    <router-link to="/ClockOut">下班</router-link>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
    var self = this
    self.login(self).then(res => {
      console.log('/ page!! errorCode: ' + this.$store.state.errorCode)
    })
  }
}
</script>

<style lang="less">

</style>
