<template>
  <div class="ClockIn">
    <div>
      <div style="padding-top: 160px;background-color:#fff;"></div>
      <div class="headImg">
        <div>
          <img v-if="this.$store.state.customerInfo.customerHeadImg" :src="this.$store.state.customerInfo.customerHeadImg" @click="onReloadPage"/>
          <img v-else src="../../assets/default-headImg.jpg"/>
        </div>
        <div class="userName">{{this.$store.state.customerInfo.customerName}}</div>
        <div class="posName">{{this.$store.state.customerInfo.channelName}}</div>
      </div>
      <div class="addressDiv">
        <div class="addressDivSub1">
          <img src="../../assets/clockin/56.png"/>
          <div class="textDiv">今日任务计划</div>
        </div>
        <div class="addressDivSub2">
          <div class="textDiv1">
            <img src="../../assets/clockin/57.png"/>
            <div class="textDiv">任务获取地址</div>
            <img src="../../assets/clockin/55.png" v-if="!isFlushGPS" @click="onFlushGPS"/>
            <img src="../../assets/clockin/55.gif" v-if="isFlushGPS"/>
          </div>
          <div class="textDiv2" v-html="addressMsg"></div>
          <div class="textDiv2" v-html="deviationMsg"></div>
        </div>
        <div>
          <van-button type="info" block round class="submitBtn" :disabled="isSubmitBtn" @click="onSubmit">点击获取任务</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: 'ClockIn',
  data () {
    return {
      attSchInfo: {
        attSchPk: '',
        allowMaxDistance: '',
        allowDistance: '',
        attStartTime: '',
        attEndTime: ''
      },
      customerAtt: {
        attSchPk: '',
        distance: '',
        customerLongitude: '', // 38.861472
        customerLatitude: '', // 121.513244
        customerId: '',
        empPk: '',
        scheduleId: '',
        scheduleDate: '',
        scheduleType: '',
        prjId: '',
        prjCode: '',
        channelId: '',
        channelCode: ''
      },
      isFlushGPS: false,
      addressMsg: '',
      deviationMsg: '与门店偏差距离:'
    }
  },
  computed: {
    isSubmitBtn: function () {
      if (this.$store.state.customerInfo.scheduleId && !this.attSchInfo.attStartTime) {
        // 未打卡,且存排班id时，认为有排班，才可以打卡
        return false
      }
      return true
    }
  },
  created () {
    var self = this
    self.login(self).then(res => {
      var empPk = self.$store.state.customerInfo.empPk
      var scheduleId = self.$store.state.customerInfo.scheduleId
      console.info('ClockIn page!!', 'empPk=', empPk, 'scheduleId=', scheduleId)
      if (!empPk || !scheduleId) {
        // 说明无排班，返回页面或给出提示
        self.toErrorPage(self, '1003', '用户无排班')
        return
      }
      // 获取考勤详情
      self.getClockInInit(self)
    })
  },
  methods: {
    getClockInInit (self) {
      return new Promise(function (resolve, reject) {
        self.$store.commit('updateLoading', true)
        // 初始化参数
        self.customerAtt.customerId = self.$store.state.customerInfo.customerId
        self.customerAtt.empPk = self.$store.state.customerInfo.empPk
        self.customerAtt.scheduleId = self.$store.state.customerInfo.scheduleId
        self.customerAtt.prjId = self.$store.state.customerInfo.prjId
        self.customerAtt.prjCode = self.$store.state.customerInfo.prjCode
        self.customerAtt.channelId = self.$store.state.customerInfo.channelId
        self.customerAtt.channelCode = self.$store.state.customerInfo.channelCode
        self.customerAtt.scheduleDate = self.$store.state.customerInfo.scheduleDate
        self.customerAtt.scheduleType = self.$store.state.customerInfo.scheduleType
        self.customerAtt.channelLongitude = self.$store.state.customerInfo.channelLongitude
        self.customerAtt.channelLatitude = self.$store.state.customerInfo.channelLatitude
        var faceOrderNo = self.$route.query.faceOrderNo
        var h5faceId = self.$route.query.h5faceId
        // 判断打卡情况，获取打卡信息
        self.$http({
          url: '/p1mk/svc/view/clockIn/v1/getClockInInit?faceOrderNo=' + faceOrderNo + '&h5faceId=' + h5faceId,
          method: 'get',
          async: false,
          params: self.$store.state.customerInfo
        }).then(res => {
          console.log('ClockIn page!!', res)
          self.$store.commit('updateLoading', false)
          if (res.data.errorCode !== '0') {
            self.toErrorPage(self, res.data.errorCode, res.data.errorMessage)
            return
          }
          // 长促不能做非ST判断
          var isLongPromotionST = res.data.data.isLongPromotionST
          if (isLongPromotionST === 'false') {
            var isLongCrossAgency = res.data.data.isLongCrossAgency
            if (isLongCrossAgency) {
              Dialog({
                message: '[10011]非常抱歉，您无法执行此任务哦！'
              }).then(() => {
                self.toErrorPage(self, '5000', '无权限访问')
              })
            } else {
              Dialog({
                message: '你合约协议有冲突，无法执行此任务！'
              }).then(() => {
                self.toErrorPage(self, '5000', '无权限访问')
              })
            }
            return
          }
          // 长促的合约判断
          var isEmpContract = res.data.data.isEmpContract
          if (isEmpContract === 'false') {
            Dialog({
              message: '无有效合约，请联系上级！'
            }).then(() => {
              self.toErrorPage(self, '5000', '无权限访问')
            })
            return
          }
          // 合同判断
          var isContractPassed = res.data.data.isContractPassed
          if (isContractPassed === 'false') {
            Dialog({
              message: '未完成合同签约,请去米咖公众号完成考勤签约。'
            }).then(() => {
              self.toErrorPage(self, '5000', '无权限访问')
            })
            return
          }
          // 刷脸判断
          var isFacecomparePassed = res.data.data.isFacecomparePassed
          if (isFacecomparePassed === 'false') {
            // 未刷脸，或者刷脸未通过
            if (res.data.data.facecompareMsg) {
              Dialog({
                message: res.data.data.facecompareMsg
              }).then(() => {
                self.$router.push('/Facecompare')
              })
            } else {
              self.$router.push('/Facecompare')
            }
            return
          }
          // 考题判断
          var isQuestionsPassed = res.data.data.isQuestionsPassed
          if (isQuestionsPassed === 'false') {
            // 未考题，或者考题未通过
            self.$router.push('/Questions')
            return
          }
          self.attSchInfo = res.data.data
          self.customerAtt.attSchPk = self.attSchInfo.attSchPk
          self.getWxJssdkSign().then(res => {
            // 获取坐标
            self.onFlushGPS()
          })
          if (res.data.data.attStartTime) {
            // 上班打卡过的，跳转回调用页面
            self.toRedirectPage(self, '0', '获取成功')
          }
        }).catch(err => {
          console.log('ClockIn page!!', err)
          self.$store.commit('updateLoading', false)
          self.toErrorPage(self, '5001', '系统异常')
        })
      })
    },
    onSubmit () {
      var self = this
      if (!self.customerAtt.customerLongitude || !self.customerAtt.customerLatitude) {
        Dialog({
          message: '未正确获取定位'
        })
        return
      }
      self.$store.commit('updateLoading', true)
      self.$http({
        url: '/p1mk/svc/view/clockIn/v1/saveClockInInfo',
        method: 'post',
        async: false,
        params: this.customerAtt
      }).then(res => {
        console.log('ClockIn page!!Submit', res)
        if (res.data.errorCode === '0') {
          self.attSchInfo.attStartTime = res.data.data.attStartTime
          self.$store.commit('updateLoading', false)
          Dialog({
            message: '获取成功'
          }).then(() => {
            self.toRedirectPage(self, '0', '获取成功')
          })
        }
      }).catch(err => {
        console.log('ClockIn page!!Submit', err)
        self.$store.commit('updateLoading', false)
        self.toErrorPage(self, '5001', '系统异常')
      })
    },
    onFlushGPS () {
      // 刷脸获取GPS
      var self = this
      self.isFlushGPS = true
      self.getBaiduLocationpoint(self).then(res => {
        self.isFlushGPS = false
        if (!res.message) {
          self.customerAtt.customerLongitude = res.lng
          self.customerAtt.customerLatitude = res.lat
          // 初始化用户与门店距离
          self.getChannelDistance(res.lng, res.lat)

          // 初始化用户地址
          self.getCustomerAddress(res.lng, res.lat)
        } else {
          Dialog({ message: res.message })
        }
      })
    },

    // 获取用户与门店距离
    getChannelDistance (lng, lat) {
      var self = this
      var channelLongitude = self.$store.state.customerInfo.channelLongitude
      var channelLatitude = self.$store.state.customerInfo.channelLatitude
      if (!channelLongitude || !channelLatitude) {
        self.customerAtt.distance = 0
        self.deviationMsg = '与门店偏差距离:<span class="abnormal">(门店没有GPS)</span>'
      } else {
        // 计算门店坐标与用户坐标的实际距离
        self.getDistance(lng, lat, channelLongitude, channelLatitude).then(res => {
          console.info('distance', res.distance)
          if (!res.distance) {
            self.customerAtt.distance = 0
            self.deviationMsg = '与门店偏差距离:<span class="abnormal">(未获取到位置距离信息)</span>'
          } else {
            self.customerAtt.distance = res.distance
            if (self.customerAtt.distance > parseInt(self.attSchInfo.allowDistance)) {
              self.deviationMsg = '与门店偏差距离:' + self.customerAtt.distance + 'm<span class="abnormal">(位置异常)</span>'
            } else {
              self.deviationMsg = '与门店偏差距离:' + self.customerAtt.distance + 'm<span class="normal">(位置正常)</span>'
            }
          }
        })
      }
    },

    // 获取用户地址
    getCustomerAddress (lng, lat) {
      var self = this
      self.getLocationAddress(lng, lat).then(res => {
        if (res.address === '') {
          self.addressMsg = '获取位置失败,可刷新重试！'
        } else {
          self.addressMsg = res.address + '(百度参考地址)'
        }
      })
    },

    // 强制刷新页面
    onReloadPage () {
      window.location.reload(true)
    }
  }
}
</script>

<style lang="less">
.ClockIn{
  background-color: #169aff;
  height: 100%;
  width: 100%;
  position: absolute;
  .van-nav-bar{
    background-color:#1989fa;
  }
  .van-nav-bar__title{
    color:#fff
  }
  .van-nav-bar .van-icon{
    color:#fff
  }
  .headImg{
    height: 29px;
    width: 100%;
    background: url(../../assets/clockin/zhuce.png) no-repeat center bottom;
    background-size:100%;
    -moz-background-size:100%;
    color:#fff;
    display: block;

    .userName{
      font-weight:bold;
      font-size:20px;
      margin-top: 10px;
    }

    .posName{
      margin-top: 10px;
      font-size:14px;
    }

    img{
      z-index:5;
      border-radius: 5rem;
      object-fit: cover;
      width: 80px;
      height: 80px;
      margin-top: -30px;
    }
  }
  .addressDiv{
    margin-top: 110px;
    color:#fff;
    .addressDivSub1{
      height: 40px;
      border-bottom: 1px solid #fff;

      img{
        float: left;
        height: 20px;
        width: 20px;
        margin-left: 20px;
        margin-right: 10px;
      }
      .textDiv{
        float: left;
        margin-top: -3px;
      }
    }
    .addressDivSub2{
      text-align: left;
      height: 150px;
      margin-top: 15px;
      .textDiv1{
        display: inline-flex;
        margin-bottom: 10px;
        img{
          height: 20px;
          width: 20px;
          margin-left: 20px;
          margin-right: 10px;
        }
        .textDiv{
          display: inline-block;
          margin-top: -3px;
        }
      }
      .textDiv2{
        margin-left: 50px;
        margin-right: 10px;
        font-size:12px;
        .abnormal{
          color: red;
        }
        .normal{
          color: green;
        }
      }
    }
    .submitBtn{
      background-color:#fff;
      color:#1989fa;
      width: 80%;
      margin-left: 10%;
    }
  }
}
</style>
