import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Register from '../views/register/Register.vue'
import ClockIn from '../views/clockIn/ClockIn.vue'
import ClockOut from '../views/clockOut/ClockOut.vue'
import Facecompare from '../views/facecompare/Facecompare.vue'
import Questions from '../views/questions/Questions.vue'
import Training from '../views/training/Training.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '提示' }
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register,
    meta: { title: '个人中心' }
  },
  {
    path: '/ClockIn',
    name: 'ClockIn',
    component: ClockIn,
    meta: { title: '任务开始' }
  },
  {
    path: '/ClockOut',
    name: 'ClockOut',
    component: ClockOut,
    meta: { title: '任务结束' }
  },
  {
    path: '/Facecompare',
    name: 'Facecompare',
    component: Facecompare,
    meta: { title: '人脸验证' }
  },
  {
    path: '/Questions',
    name: 'Questions',
    component: Questions,
    meta: { title: '任务须知' }
  },
  {
    path: '/Training',
    name: 'Training',
    component: Training,
    meta: { title: '任务须知' }
  }
]

const router = new VueRouter({
  routes
})

export default router
