<template>
<div class="Register">
</div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
    var self = this
    self.login(self).then(res => {
      // 设置Loading
      var qyOpenId = self.$store.state.qyOpenId || sessionStorage.getItem('qyOpenId')
      var orgCode = self.$store.state.orgCode
      var token = self.$store.state.token
      var pqyOpenId = self.$store.state.pqyOpenId
      var appCode = self.$store.state.appCode
      console.log('Register page!!', 'qyOpenId=', qyOpenId, 'pqyOpenId=', pqyOpenId, 'appCode=', appCode)
      self.$store.commit('updateLoading', true)
      // 回调redirect_uri，或跳转到CRM进行注册
      self.$http({
        url: '/p1mk/svc/view/customer/v1/getRegisterInfo',
        method: 'get',
        async: false,
        params: {
          qyOpenId,
          customerSource: orgCode,
          pqyOpenId,
          token,
          appCode
        }
      }).then(res => {
        console.log(res)
        self.$store.commit('updateLoading', false)
        // 1002 未注册 1006 未身份认证 1007 未实名认证 1008 未银行卡认证
        if (res.data.errorCode === '1002' || res.data.errorCode === '1006' || res.data.errorCode === '1007' || res.data.errorCode === '1008') {
          sessionStorage.removeItem('qyOpenId')
          sessionStorage.removeItem('redirectUri')
          sessionStorage.removeItem('orgCode')
          sessionStorage.removeItem('appCode')
          sessionStorage.removeItem('scheduleId')
          // alert('window.location2' + res.data.data.registerUrl)
          // 去CRM注册
          document.location = res.data.data.registerUrl
        } else if (res.data.errorCode === '0') {
          self.toErrorPage(self, '0', '用户已注册')
        } else {
          self.toErrorPage(self, res.data.errorCode, res.data.errorMessage)
        }
      }).catch(err => {
        console.log(err)
        self.$store.commit('updateLoading', false)
        self.toErrorPage(self, '5001', '系统异常')
      })
    })
  }
}
</script>

<style lang="less">

</style>
