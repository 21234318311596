<template>
  <div id="app">
    <router-view/>
    <div class="load_cover" v-if="this.$store.state.showLoading">
      <van-loading type="spinner"/>
    </div>
  </div>
</template>

<script>
export default {
  created () {}
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .van-loading__circular circle{
    stroke-width: 5px;
  }
  .van-loading{
    width: 40px;
    height: 40px;
    top: 40%;
    left: 50%;
    transform: translateX(-50%)
  }
  .load_cover{
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.5);
    z-index: 999;
    position: fixed;
    left: 0;
    top:0;
  }
}
</style>
