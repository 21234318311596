<template>
  <div class="Questions">
    <div class="formDiv">
      <van-form @submit="onSubmit">
        <div v-for="item in questionsList" :key="item.id" class="radioGroup">
          <div v-html="item.content" class="radioContent"></div>
          <van-radio-group v-model="answerList[item.id]" v-if="item.questionType != '1'">
            <van-radio name="A" v-if="item.optionA">{{item.optionA}}</van-radio>
            <van-radio name="B" v-if="item.optionB">{{item.optionB}}</van-radio>
            <van-radio name="C" v-if="item.optionC">{{item.optionC}}</van-radio>
            <van-radio name="D" v-if="item.optionD">{{item.optionD}}</van-radio>
            <van-radio name="E" v-if="item.optionE">{{item.optionE}}</van-radio>
          </van-radio-group>
          <van-checkbox-group v-model="answerList[item.id]" v-if="item.questionType == '1'">
            <van-checkbox name="A" v-if="item.optionA">{{item.optionA}}</van-checkbox>
            <van-checkbox name="B" v-if="item.optionB">{{item.optionB}}</van-checkbox>
            <van-checkbox name="C" v-if="item.optionC">{{item.optionC}}</van-checkbox>
            <van-checkbox name="D" v-if="item.optionD">{{item.optionD}}</van-checkbox>
            <van-checkbox name="E" v-if="item.optionE">{{item.optionE}}</van-checkbox>
          </van-checkbox-group>
        </div>

        <div v-if="submitShow">
          <van-button round block type="info" class="submitBtn" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: 'Questions',
  data () {
    return {
      questionsList: [],
      answerList: {},
      submitShow: false
    }
  },
  created () {
    var self = this
    self.login(self).then(res => {
      var prjId = self.$store.state.customerInfo.prjId
      var empPk = self.$store.state.customerInfo.empPk
      var scheduleId = self.$store.state.customerInfo.scheduleId
      console.log('Questions page!!', 'prjId=', prjId, 'empPk=', empPk)
      // 设置Loading
      self.$store.commit('updateLoading', true)
      self.$http({
        url: '/p1mk/svc/view/questions/v1/getQuestionsList',
        method: 'get',
        async: false,
        params: { prjId, empPk, scheduleId }
      }).then(res => {
        console.log(res)
        if (res.data.errorCode !== '0') {
          self.toErrorPage(self, res.data.errorCode, res.data.errorMessage)
        } else if (res.data.data.isPassed === 'true') {
          self.$router.push('/ClockIn')
        }
        self.questionsList = res.data.data.list
        self.$store.commit('updateLoading', false)
        self.submitShow = true
      }).catch(err => {
        console.log(err)
        self.$store.commit('updateLoading', false)
        self.toErrorPage(self, '5001', '系统异常')
      })
    })
  },
  methods: {
    onSubmit () {
      var self = this
      var posId = self.$store.state.customerInfo.channelId
      var scheduleId = self.$store.state.customerInfo.scheduleId
      var scheduleType = self.$store.state.customerInfo.scheduleType
      var prjId = self.$store.state.customerInfo.prjId
      var empPk = self.$store.state.customerInfo.empPk
      const arr = []
      for (const key in self.answerList) {
        if (Array.isArray(self.answerList[key])) {
          var checkedValue = ['A', 'B', 'C', 'D', 'E']
          var checkboxChecked = ''
          for (const ched in checkedValue) {
            for (const item in self.answerList[key]) {
              if (self.answerList[key][item] === checkedValue[ched]) {
                checkboxChecked = checkboxChecked + self.answerList[key][item] + '#'
                break
              }
            }
          }
          if (checkboxChecked !== '') {
            checkboxChecked = checkboxChecked.slice(0, checkboxChecked.length - 1)
          }
          arr.push(checkboxChecked)
        } else {
          arr.push(self.answerList[key])
        }
      }
      // 考题的ID列表
      var ansIds = ''
      // 考题的正确答案列表
      var ransDetail = ''
      // 考题的选择答案列表
      var ansDetail = arr.join(',')
      console.log('ansDetail', arr.join(','))
      for (let i = 0; i < self.questionsList.length; i++) {
        ransDetail = ransDetail + self.questionsList[i].answer
        ansIds = ansIds + self.questionsList[i].id
        if (i < self.questionsList.length - 1) {
          ransDetail = ransDetail + ','
          ansIds = ansIds + ','
        }
      }
      console.log(ransDetail + ',' + ansIds)
      if (ransDetail.length > ansDetail.length) {
        Dialog({ message: '有题目没有作答时无法提交哦！' })
      }
      if (prjId && empPk && self.questionsList.length === arr.length) {
        // 设置Loading
        self.$store.commit('updateLoading', true)
        self.$http({
          url: '/p1mk/svc/view/questions/v1/saveQuestionAnswer',
          method: 'get',
          async: false,
          params: {
            posId,
            scheduleId,
            scheduleType,
            prjId,
            empPk,
            ransDetail,
            ansDetail,
            ansIds
          }
        }).then(res => {
          console.log('返回值', res.data)
          // 1:考勤通过，2：有考题答错，3：考题达到最大次数
          if (res.data.data.answerResult === '1') {
            self.$router.push('/ClockIn')
          } else if (res.data.data.answerResult === '2') {
            Dialog({
              message: ' 很遗憾，您有' + res.data.data.errNum + '题答错了，不能通过，再温习一下吧！'
            }).then(() => {
              self.$router.push('/Training')
            })
          } else if (res.data.data.answerResult === '3') {
            Dialog({ message: ' 您今日答题次数达到最大次数，无法进行考勤，请联系督导！' })
          } else {
            Dialog({ message: ' 服务器不知道怎么啦!请稍候再试! ' })
          }
          self.$store.commit('updateLoading', false)
          self.submitShow = true
        }).catch(err => {
          console.log(err)
          self.$store.commit('updateLoading', false)
        })
      }
    }
  }
}
</script>

<style lang="less">
.Questions{
  margin-top : 46px;
  margin-bottom : 40px;
  .van-nav-bar{
    background-color:#1989fa
  }
  .van-nav-bar__title{
    color:#fff
  }
  .van-nav-bar .van-icon{
    color:#fff
  }
  .formDiv{
    .radioGroup{
      padding:10px 20px 10px 20px;
      border-bottom:10px solid #f3f8f9;
      font-size: 14px;
      margin-bottom: 12px;
    }
    .radioContent{
      font-size: 16px;
      text-align:left;
      margin-bottom: 12px;
    }
    .van-radio__label{
      line-height:25px;
      margin-bottom: 10px;
      color: #707070;
      vertical-align: middle;
      text-align: left;
    }
    .van-radio{
      align-items:initial;
    }
    .van-checkbox__label{
      line-height:25px;
      margin-bottom: 10px;
      color: #707070;
      vertical-align: middle;
      text-align: left;
    }
    .van-checkbox{
      align-items:initial;
    }
    .van-checkbox__icon--round .van-icon{
      border-radius:0;
    }
    .submitBtn{
      width: 80%;
      margin-left: 10%;
    }
  }
}
</style>
