import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vant from 'vant'
import 'vant/lib/index.css'
import base from './base'
import wx from 'weixin-js-sdk'

Vue.use(Vant)
Vue.use(base)
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.wx = wx

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) document.title = to.meta.title
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
