exports.install = function (Vue, options) {
  /**
   * 进入页面加载，获取用户基本信息
   * page当前Vue实例，表示当前页面
   * isDev是否为开发模式
   */
  Vue.prototype.login = function (page, isDev = false) {
    return new Promise(function (resolve, reject) {
      // page.outputLogs('base-login', 'schedulePk=' + page.$route.query.schedulePk + ',scheduleId=' + page.$store.state.scheduleId + ',qyOpenId=' + page.$route.query.openid)
      page.outputLogs('base-login', 'schedulePk=' + page.$route.query.schedulePk + ',scheduleId=' + page.$store.state.scheduleId + ',qyOpenId=' + page.$route.query.openid + ',g=' + page.$route.query.g + ',a=' + page.$route.query.agent + ',p=' + page.$route.query.p + ',token=' + page.$route.query.token + ',r=' + page.$route.query.redirect_uri)
      // 排班变更,需更新缓存
      if (page.$route.query.schedulePk && page.$route.query.schedulePk !== page.$store.state.scheduleId) {
        sessionStorage.removeItem('scheduleId')
        page.$store.commit('changeCustomerInfo', {})
        console.info('测试！！', page.$route.query.schedulePk, page.$store.state.scheduleId)
      }
      if (page.$route.query.error) {
        page.$store.commit('updateErrorCode', page.$route.query.error)
        resolve()
        return
      }
      if (page.$store.state.qyOpenId && page.$store.state.customerInfo.customerId) {
        // qyOpenId存在，不在获取
        resolve()
        return
      }
      // g表示租户
      let g = page.$route.query.g
      // a表示租户的子应用ID
      let a = page.$route.query.agent
      // p表示普乐师提供的应用
      let p = page.$route.query.p
      // 重定向地址,已转码
      const r = page.$route.query.redirect_uri
      // 授权token
      let token = page.$route.query.token
      // 在接入方被权限系统跳转时，路径带有openid
      let openid = page.$route.query.openid
      // 排班ID，进入指定排班的页面
      const schedulePk = page.$route.query.schedulePk
      // 排班ID，进入指定排班的页面
      const from = page.$route.query.from
      // alert('login !!g=' + g + ' a=' + a + ' p=' + p + ' openid=' + openid + ' token=' + token + ' redirect_uri=' + r + ' scheduleId=' + schedulePk)
      // 测试用
      if (isDev) {
        g = 'ORGCM3559'
        a = ''
        p = 'test3'
        // r = ''
        token = ''
        openid = 'oC32jweZ56MsMoL9tQn3KOJ7lX10'
        sessionStorage.setItem('qyOpenId', openid)
        sessionStorage.setItem('orgCode', g)
        sessionStorage.setItem('appCode', p)
        page.$store.commit('setDev', isDev)
        console.info('开启测试模式！！')
      }

      // sessionStorage不存在qyOpenId去url或cookie取，若取不到则说明访问异常
      let qyOpenId = sessionStorage.getItem('qyOpenId')
      if (!qyOpenId || qyOpenId === 'undefined' || qyOpenId === 'null') {
        if (openid) {
          page.$store.commit('updatePQyOpenId', openid)
          const openidS = decodeURIComponent(openid)
          const openidA = openidS.split(':')
          qyOpenId = openidA[0]
        } else {
          // 获取cookie里的企业微信openid
          const openidCookieName = 'openid_' + g + '_' + a
          const openidCookieValue = page.getcookie(openidCookieName)
          if (openidCookieValue) {
            const openidCookieValues = openidCookieValue.split(':')
            qyOpenId = openidCookieValues[0]
          }
          console.info('login !!', 'openidCookieName=', openidCookieName, 'openidCookieValue=', openidCookieValue)
        }
        // openid和token用于权限系统的绑定接口的参数
        page.$store.commit('updatePQyOpenId', openid)
        page.$store.commit('updateToken', token)
        page.$store.commit('updateQyOpenId', qyOpenId)
        console.info('login !!', 'g=', g, 'a=', a, 'p=', p, 'qyOpenId=', qyOpenId, 'token=', token, 'redirect_uri=', r, 'scheduleId=', schedulePk)

        // 针对跳转页面时对丢，要存在sessionStorage
        sessionStorage.setItem('redirectUri', decodeURIComponent(r))
        sessionStorage.setItem('qyOpenId', qyOpenId)
        sessionStorage.setItem('scheduleId', schedulePk)
        sessionStorage.setItem('from', from)
      }

      let scheduleId = sessionStorage.getItem('scheduleId')

      if (schedulePk) {
        if (!scheduleId || scheduleId === 'undefined' || scheduleId === 'null' || scheduleId !== schedulePk) {
          scheduleId = schedulePk
          page.$store.commit('updateScheduleId', schedulePk)
          sessionStorage.setItem('scheduleId', schedulePk)
        }
      }
      let orgCode = sessionStorage.getItem('orgCode')
      if (!orgCode || orgCode === 'undefined' || orgCode === 'null') {
        orgCode = g
        page.$store.commit('updateOrgCode', g)
        sessionStorage.setItem('orgCode', g)
      }
      let appCode = sessionStorage.getItem('appCode')
      if (!appCode || appCode === 'undefined' || appCode === 'null') {
        appCode = p
        page.$store.commit('updateAppCode', p)
        sessionStorage.setItem('appCode', p)
      }
      const hashVaule = encodeURIComponent('/#' + page.$route.path)
      console.info('login !!', 'hashVaule=', hashVaule)
      page.$store.commit('updateLoading', true)
      page.$http({
        url: '/p1mk/svc/view/customer/v1/getCustomerInfo',
        method: 'get',
        async: false,
        params: {
          hashVaule: hashVaule,
          qyOpenId,
          orgCode,
          scheduleId
        }
      }).then(res => {
        console.log('login !!', res)
        page.$store.commit('updateLoading', false)
        if (res.data.errorCode === '0') {
          // 通过接口获取的后台数据保存到store中，等待组件取用
          page.$store.commit('changeCustomerInfo', res.data.data)
          resolve()
        } else if (res.data.errorCode === '5000') {
          page.outputLogs('base-login5000', 'g=' + g + ',a=' + a + ',p=' + p + ',qyOpenId=' + qyOpenId)
          // 无权访问
          page.toErrorPage(page, res.data.errorCode, res.data.errorMessage)
        } else {
          resolve()
        }
      }).catch(err => {
        console.log('login !!', err)
        // 通过接口获取的后台数据保存到store中，等待组件取用
        page.$store.commit('updateErrorCode', '5001:系统异常')
        page.$store.commit('updateLoading', false)
        page.$router.push('/')
      })
    })
  }

  // 设置cookie值
  Vue.prototype.setcookie = function (name, value) {
    // 设置名称为name,值为value的Cookie
    // 初始化时间
    var expdate = new Date()
    // 时间单位毫秒 默认30分钟
    expdate.setTime(expdate.getTime() + 15 * 60 * 1000)
    document.cookie = name + '=' + escape(value) + ';expires=' + expdate.toGMTString() + ';path=/'
    // 即document.cookie= name+"="+value+";path=/";  时间默认为当前会话可以不要，但路径要填写，因为JS的默认路径是当前页，如果不填，此cookie只在当前页面生效！
  }

  // 删除cookie值
  Vue.prototype.delCookie = function (name) {
    var expdate = new Date(0).toGMTString()
    document.cookie = name + '=;expires=' + expdate + ';path=/'
  }

  // 获取cookie值
  Vue.prototype.getcookie = function (name) {
    var d
    var cookieItems = document.cookie.split(';')
    for (let i = 0; i < cookieItems.length; i++) {
      var cookieItem = cookieItems[i].split('=')
      if (name === cookieItem[0].toString().trim()) {
        d = cookieItem[1]
        break
      }
    }
    if (d) {
      var g = unescape(d.trim())
      return g
    } else {
      return ''
    }
  }

  // 获取百度坐标(先H5在企业微信)
  Vue.prototype.isWeixin = function () {
    var ua = navigator.userAgent.toLowerCase()
    console.log('isWeixin !!', ua, ua.indexOf('micromessenger') > 0)
    if (ua.indexOf('micromessenger') > 0) {
      return true
    } else {
      return false
    }
  }

  // 获取百度坐标(先H5在企业微信)
  Vue.prototype.getBaiduLocationpoint = function (page) {
    return new Promise(function (resolve, reject) {
      if (page.isWeixin()) {
        page.getBaiduLocationpointQY(page).then(res => {
          if (!res.message) {
            resolve({ message: res.message, lng: res.lng, lat: res.lat })
          } else {
            page.getBaiduLocationpointH5(page).then(res1 => {
              if (res1.lng && res1.lat) {
                resolve({ message: '', lng: res1.lng, lat: res1.lat })
              }
              resolve({ message: res1.message })
            })
          }
        })
      } else {
        page.getBaiduLocationpointH5(page).then(res => {
          if (res.lng && res.lat) {
            resolve({ message: '', lng: res.lng, lat: res.lat })
          }
          resolve({ message: res.message })
        })
      }
    })
  }

  // 获取百度坐标(H5版)
  Vue.prototype.getBaiduLocationpointH5 = function (page) {
    return new Promise(function (resolve, reject) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // 获取经度
            var longitude = position.coords.longitude
            // 获取纬度
            var latitude = position.coords.latitude
            console.info('getlocationpoint H5定位坐标', longitude, latitude)
            // 转百度坐标
            var pt = new window.BMap.Point(longitude, latitude)
            var convertor = new window.BMap.Convertor()
            convertor.translate([pt], 1, 5, function (data) {
              var lng = data.points[0].lng.toFixed(6)
              var lat = data.points[0].lat.toFixed(6)
              console.info('getlocationpoint 百度坐标', lng, lat)
              resolve({ message: '', lng, lat })
            })
          },
          function (error) {
            console.info('定位失败', error.code, error.message)
            page.outputLogs('base-定位失败', error.code + ':' + error.message)
            var message = '定位失败，未知错误'
            if (error.code === 1) {
              message = '位置服务被拒绝'
            } else if (error.code === 2) {
              message = '暂时获取不到位置信息'
            } else if (error.code === 3) {
              message = '获取位置信息超时'
            } else if (error.code === 4) {
              message = '定位失败，未知错误'
            }
            resolve({ message })
          })
      } else {
        resolve({ message: '不支持定位功能' })
      }
    })
  }

  // 获取百度坐标(企业微信版)
  Vue.prototype.getBaiduLocationpointQY = function (page) {
    return new Promise(function (resolve, reject) {
      console.log('getlocationpoint QY!! 进入企业微信定位')
      page.wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          // 获取经度
          var longitude = res.longitude // 经度，浮点数，范围为180 ~ -180。
          // 获取纬度
          var latitude = res.latitude // 纬度，浮点数，范围为90 ~ -90
          console.info('getlocationpoint QY定位坐标', longitude, latitude)
          // 转百度坐标
          var pt = new window.BMap.Point(longitude, latitude)
          var convertor = new window.BMap.Convertor()
          convertor.translate([pt], 1, 5, function (data) {
            var lng = data.points[0].lng.toFixed(6)
            var lat = data.points[0].lat.toFixed(6)
            console.info('getlocationpoint 百度坐标', lng, lat)
            resolve({ message: '', lng, lat })
          })
        },
        fail: function (err) {
          console.log('getlocationpoint QY!! error', err)
          page.outputLogs('base-定位失败QY', err.errMsg)
          resolve({ message: '不支持定位功能' })
        }
      })
    })
  }

  // 获取两组坐标的距离，如用户与门店坐标距离
  Vue.prototype.getDistance = function (lng1, lat1, lng2, lat2) {
    return new Promise(function (resolve, reject) {
      let distance = 0
      if (lng1 && lat1 && lng2 && lat2) {
        // 计算门店坐标与用户坐标的实际距离
        const point1 = new window.BMap.Point(lng1, lat1)
        const point2 = new window.BMap.Point(lng2, lat2)
        distance = window.BMapLib.GeoUtils.getDistance(point1, point2)
        distance = distance.toFixed(0)
      }
      console.info('getDistance获取两坐标距离', distance)
      resolve({ distance })
    })
  }

  // 根据坐标解析详细地理位置 逆地址解析
  Vue.prototype.getLocationAddress = function (lng1, lat1) {
    return new Promise(function (resolve, reject) {
      var point = new window.BMap.Point(lng1, lat1)
      var address = ''
      if (!lng1 || !lat1) {
        resolve({ address })
      }
      new window.BMap.Geocoder().getLocation(point, function (rs) {
        var addComp = rs.addressComponents
        var address = addComp.city + addComp.district + addComp.street + addComp.streetNumber
        console.info('getLocationAddress 逆地址解析', address)
        resolve({ address })
      })
    })
  }

  // 错误跳转,是回调到指定路径，还是跳转到错误提示页面
  Vue.prototype.toErrorPage = function (page, errorCode, errorMessage) {
    var redirectUri = sessionStorage.getItem('redirectUri')
    console.log('toErrorPage !!', errorCode, errorMessage, redirectUri)
    if (redirectUri && redirectUri !== 'undefined' && redirectUri !== 'null') {
      // 业务处理完跳转前,清理sessionStorage中redirectUri
      sessionStorage.removeItem('qyOpenId')
      sessionStorage.removeItem('redirectUri')
      sessionStorage.removeItem('orgCode')
      sessionStorage.removeItem('appCode')
      sessionStorage.removeItem('scheduleId')
      if (errorCode !== '0') {
        const error = encodeURIComponent(errorCode + ':' + errorMessage)
        if (redirectUri.indexOf('?') !== -1) {
          redirectUri = redirectUri + '&error=' + error
        } else {
          redirectUri = redirectUri + '?error=' + error
        }
      }
      // if (confirm(redirectUri) === true) {
      window.location.href = redirectUri
      // }
    } else {
      page.$store.commit('updateErrorCode', errorCode + ':' + errorMessage)
      page.$router.push('/')
    }
  }

  // 重定向到对接业务场景,redirectUri为空时停在当前页面
  Vue.prototype.toRedirectPage = function (page, errorCode, errorMessage) {
    var redirectUri = sessionStorage.getItem('redirectUri')
    console.log('toRedirectPage !!', errorCode, errorMessage, redirectUri)
    if (redirectUri && redirectUri !== 'undefined' && redirectUri !== 'null') {
      // 业务处理完跳转前,清理sessionStorage中redirectUri
      sessionStorage.removeItem('qyOpenId')
      sessionStorage.removeItem('redirectUri')
      sessionStorage.removeItem('orgCode')
      sessionStorage.removeItem('appCode')
      sessionStorage.removeItem('scheduleId')
      if (errorCode !== '0') {
        const error = encodeURIComponent(errorCode + ':' + errorMessage)
        if (redirectUri.indexOf('?') !== -1) {
          redirectUri = redirectUri + '&error=' + error
        } else {
          redirectUri = redirectUri + '?error=' + error
        }
      }
      // if (confirm(redirectUri) === true) {
      window.location.href = redirectUri
      // }
    }
  }

  // 前端日志输出到后端
  Vue.prototype.getWxJssdkSign = function () {
    var self = this
    return new Promise(function (resolve, reject) {
      if (!self.isWeixin()) {
        resolve()
      } else {
        var signUrl = window.location.protocol + '//' + window.location.host + '/'
        var appCode = self.$store.state.appCode
        if (!appCode || appCode === 'undefined' || appCode === 'null') {
          appCode = sessionStorage.getItem('appCode')
        } else if (!appCode || appCode === 'undefined' || appCode === 'null') {
          // appCode是授权系统返回的,正常是一定有值的，这里仅做个补偿
          appCode = 'wxqx20ocpciskmcje'
        }
        self.$http({
          url: '/p1mk/svc/view/util/v1/getWxJssdkSign',
          method: 'get',
          async: true,
          params: {
            appCode, signUrl
          }
        }).then(res => {
          if (res.data.errorCode === '0') {
            self.wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.data.appId, // 必填，企业微信的corpID
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
              jsApiList: ['openLocation', 'getLocation'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            })
            self.wx.ready(function () {
              resolve()
            })
            self.wx.error(function (err) {
              console.log('getWxJssdkSign !! wx.error', err)
            })
          }
        }).catch(err => {
          console.log('getWxJssdkSign !!', err)
        })
      }
    })
  }

  // 前端日志输出到后端
  Vue.prototype.outputLogs = function (module, info) {
    var userAgent = navigator.userAgent
    var qyOpenId = sessionStorage.getItem('qyOpenId')
    this.$http({
      url: '/p1mk/svc/view/util/v1/outLogger',
      method: 'get',
      async: true,
      params: {
        userAgent, qyOpenId, module, info
      }
    })
  }
}
