<template>
  <div class="Facecompare">
  </div>
</template>

<script>
export default {
  name: 'Facecompare',
  data () {
    return {
    }
  },
  created () {
    var self = this
    self.login(self).then(res => {
      var customerId = self.$store.state.customerInfo.customerId
      var scheduleId = self.$store.state.customerInfo.scheduleId
      var from = sessionStorage.getItem('from')
      console.info('Facecompare page!!', 'customerId=', customerId, 'scheduleId=', scheduleId)
      // 设置Loading
      self.$store.commit('updateLoading', true)
      self.$http({
        url: '/p1mk/svc/view/facecompare/v1/getFacecompareInit2?from=' + from,
        method: 'get',
        async: false,
        params: self.$store.state.customerInfo
      }).then(res => {
        console.log(res)
        if (res.data.data.isPassed === 'true') {
          self.$router.push('/Questions')
        } else if (res.data.data.facecompareUrl) {
          // 调整腾讯刷脸页面
          window.location.href = res.data.data.facecompareUrl
        } else {
          self.$store.commit('updateErrorCode', res.data.errorCode + ':' + res.data.errorMessage)
          self.$router.push('/')
        }
        self.$store.commit('updateLoading', false)
      }).catch(err => {
        console.log(err)
        self.$store.commit('updateLoading', false)
        self.toErrorPage(self, '5001', '系统异常')
      })
    })
  },
  methods: {
  }
}
</script>
